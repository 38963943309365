<template>
  <div class="bread clearfloat">
    <el-breadcrumb  separator="/">
      <el-breadcrumb-item v-for="(item, index) in options" :key="index" :to="item.url"><i v-if="index == 0" class="el-icon-s-order" style="color: #e67700;"></i> {{ item.name }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {

    }
  }

}
</script>

<style>
.bread {background-color: #fff; padding: 1rem 10% 2rem;text-align: right; max-width: 1920px; margin: 0 auto;}
.bread .el-breadcrumb {float: right; padding-right: 10%; }
</style>