<template>
  <section class="companyNew">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <BreadCrumb :options="options"></BreadCrumb>
    <div class="container">
      <el-tabs :tab-position="tabPosition" class="tab" v-model="editableTabsValue" @tab-click="handleClick">
        <el-tab-pane class="new_my" name="new_my" label="公司新闻" :disabled="isDisabled"></el-tab-pane>
        <el-tab-pane name="1" label="公司监测系统升级" :lazy="lazy">
          <section class="article">
            <h5>公司监测系统升级</h5>
            <p class="title">日期：2022年1月11日</p>
            <p>近期，公司技术人员为上海特高压变电站升级SF6（六氟化硫）气体微水压力在线监测系统。</p>
            <p>SF6气体以其优异的绝缘和灭弧性能，在电力系统中获得了广泛应用，成为中特高压、超高压开关中普遍使用的绝缘和灭弧介质。如果在开关运行过程中发生SF6气体泄漏或水分超标时，对运行设备的绝缘性能会造成较大的影响，程度严重的会导致绝缘击穿，造成重大事故，通过建立SF6微水压力在线监测系统，可实时监测SF6气体运行状态，及时发现SF6气体缺陷，并对气体运行趋势作出智能判断，从而有效避免SF6气体问题威胁到电网运行安全，大大增强设备及电网运行安全可靠性。</p>
            <img src="../assets/images/companyNew/1.png" alt="">
          </section>
        </el-tab-pane>
        <el-tab-pane name="2" label="第三代助力飞" :lazy="lazy">
          <section class="article">
            <h5>第三代助力飞</h5>
            <p class="title">日期：2021年7月9日</p>
            <p>近期，旋荣科技发布第三代“助力飞”现代智慧供应链SaaS平台，这标志着“助力飞”的功能又登上一个新的台阶。</p>
            <h6>（一）功能模块</h6>
            <p>新版“助力飞”是为服务商/电商企业的内部业务管理量身定做，能为使用系统的用户带来订单的业务系统。助力飞有比价平台PP，厂家直供平台MDSP、库存实时共享子系统RISS、电商实时价子系统REPS、供应商履约平台VPP、商品管理平台CMP、客户关系管理子系统CRM、供应商关系管理子系统SRM、财务对账子系统、报表系统BW、驾驶舱系统、移动端。新版“助力飞”包含14个子系统，可以根据实际需要进行配置。</p>
            <img src="../assets/images/companyNew/2.png" alt="">
            <h6>（二）业务流程</h6>
            <p>新版“助力飞”可以满足各类复杂业务流程的处理要求，可以对流程进行自定义设置。</p>
            <img src="../assets/images/companyNew/3.png" alt="">
            <h6>（三）决策支持</h6>
            <p>新版“助力飞”把OA、WMS、TMS、CRM、VP等系统充分结合起来，让管理系统不再仅仅是一个事后记账的工具，而是把决策的过程和审批的流程都融合进系统中，直线提升工作效率，降本增效。</p>
            <img src="../assets/images/companyNew/4.png" alt="">
            <p>旋荣科技在多年物资供应经验的基础上，自主研发了具有完全知识产权的“助力飞”现代智慧供应链SaaS平台，提高制造商、代理商、集货商、零售商在流通领域的内、外部流转效率，降低全社会库存，为用户不断赋能，降本增效。同时，公司也紧跟时代脉搏，积极为各类企业提供综合物资供应服务，在实践中促进助力飞SaaS平台的不断完善和优化升级。</p>
          </section>
        </el-tab-pane>
        <el-tab-pane name="3" label="先进基层党组织" :lazy="lazy">
          <section class="article">
            <h5>先进基层党组织</h5>
            <p class="title">日期：2021年6月10日</p>
            <p>近期，上海科技京城党委召开“两优一先”表彰大会，授予上海旋荣科技股份有限公司党支部“先进基层党支部”荣誉称号，同时，授予公司党支部书记吴晓春先生“优秀党务工作者”荣誉称号，授予公司董事长赵潇玮先生、董事王萍女士“优秀党员”荣誉称号。</p>
            <img class="w50" src="../assets/images/companyNew/5.png" alt="">
            <p>公司党支部在上级党委的领导下，积极开展各项党建工作，积极参与各项公益活动，得到了上级党委的高度认可。</p>
            <img class="w50" src="../assets/images/companyNew/6.png" alt="">
            <p>今后，公司党支部将坚定不移树牢党旗指引方向，团结带领全体党员干部职工，坚定信心、顽强拼搏、锐意进取，在传统化工行业转型发展上蹚出一条新路，为全方位推进高质量发展、奋力谱写全面建设社会主义现代化国家而努力奋斗。</p>
          </section>
        </el-tab-pane>
        <el-tab-pane name="4" label="子公司荣获高新证书" :lazy="lazy">
          <section class="article">
            <h5>全资子公司取得《高新技术企业证书》</h5>
            <p class="title">日期：2022年2月22日</p>
            <p>近期，旋荣科技的全资子公司上海旋胜科技有限公司取得《高新技术企业证书》，有效期三年，公司将在税收、人才引进等方面享受各项优惠政策。</p>
            <img class="w50" src="../assets/images/companyNew/7.png" alt="">
            <p>上海旋胜科技有限公司成立于2018年，主要从事软件开发、信息技术服务、技术咨询等业务。</p>
          </section>
        </el-tab-pane>
        <el-tab-pane name="5" label="视觉AI精确识别" :lazy="lazy">
          <section class="article">
            <h5>视觉AI精确识别项目全面展开</h5>
            <p class="title">日期：2022年5月</p>
            <p>2022年5月，公司的视觉AI精确识别项目全面展开，利用AI图像增强网络、多尺度检测模型等先进技术，有效提高客户的物资管理效率。经过我司多年的积累和与中科院等知名高校的联合研发，已经形成了一套可商用的，基于视觉AI精确识别的核心技术，区别于目前市面上大部分的视觉识别技术，精确识别这项具有跨越性价值的技术，将改变未来的整个视觉识别行业。</p>
            <img src="../assets/images/companyNew/8.png" alt="">
          </section>
        </el-tab-pane>
        <el-tab-pane name="6" label="助力飞“项目管理”" :lazy="lazy">
          <section class="article">
            <h5>助力飞现代智慧供应链SaaS平台的“项目管理”模块上线</h5>
            <p class="title">日期：2022年5月</p>
            <p>2022年6月，“助力飞”现代智慧供应链SaaS平台的“项目管理”模块上线，本模块可以清晰地展现各个项目的进展情况，便于各级管理层对项目进行实时跟踪，并且可以根据项目实际情况进行订制，项目管理驾驶舱示例如下：</p>
            <img src="../assets/images/companyNew/9.png" alt="">
          </section>
        </el-tab-pane>
        <el-tab-pane name="7" label="加入电力行业协会" :lazy="lazy">
          <section class="article">
            <h5>公司加入上海市电力工程行业协会</h5>
            <p class="title">日期：2022年8月</p>
            <p>2022年8月，公司加入上海市电力工程行业协会，本协会由上海市辖区内从事电力工程建设相关业务的企事业单位及其他组织自愿结成的行业联合组织，是经上海市民政局批准的非营利性社会团体法人。协会将在项目申报、市场拓展、产品研发等方面给公司大力支持。</p>
            <img src="../assets/images/companyNew/10.png" alt="">
          </section>
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>

<script>
import imageBanner from '../components/ImageBanner.vue'
import BreadCrumb from '../components/BreadCrumb.vue'
export default {
  name: 'companyNew',
  components: {imageBanner, BreadCrumb},
  data () {
    return {
      options: {name: '新闻资讯', url: '/news'},
      tabPosition: 'left',
      isDisabled: false,
      editableTabsValue: '1',
      lazy: true,
      imgBannerObj: {
        url: require('../assets/images/aboutus/4.png')
      }
    }
  },
  watch: {
    $route () {
      this.getEditableTabsValue()
    }
  },
  created () {
    this.getEditableTabsValue()
  },
  methods: {
    getEditableTabsValue () {
      if ( this.$route.query.id ) this.editableTabsValue = this.$route.query.id
    },
    handleClick (org) {
      console.log(org)
      if(org.index == 0 && /new_my/.test(org.$el.className)) {
        this.$router.push({path: '/news', query: {id: 'news_company'}})
      }
    }
  }
}
</script>

<style scoped>
/* .companyNew{max-width:1920px;margin:0 auto;background-color:#ecf0f5;padding-bottom:1rem;} */
/* .tab{font-size:1rem;margin:2rem 15%;} */
.companyNew .article{padding:2rem 5%;background-color:#fff;text-align:left;line-height:1.6;min-height: 20rem;}
.companyNew .article h5{text-align:center;font-size:1.8rem;font-weight:700;}
.companyNew .article .title{font-size:.8rem;text-align:center;padding:.6rem 0 0;text-indent:0;}
.companyNew .article .title span{display:inline-block;margin:0 2rem;}
.companyNew .article p{text-indent:2em;padding:.5rem 0;}
.companyNew .article img{display:block;width:100%;margin:0 auto;}
.companyNew .article img.w50{width:50%;}
/* .companyNew /deep/ .el-tabs__nav{background-color:#fff;}
.companyNew /deep/ .el-tabs--left .el-tabs__item{padding:.5rem 1rem;text-align:center !important;font-size:1rem;height:auto;line-height:auto;}
.companyNew /deep/ .el-tabs__item.is-active{background-color:#a2bee7;color:#fff;font-weight:600;}
.companyNew /deep/ .el-tabs__item.is-disabled{font-size:1.6rem;padding:1.4rem 2rem;background-color:#7895be !important;color:#fff;}
.companyNew /deep/ #tab-new_my.el-tabs__item{font-size:1.6rem;padding:1.4rem 2rem;background-color:#7895be !important;color:#fff;}
.companyNew /deep/ .el-tabs--left .el-tabs__active-bar{height:0 !important;} */
</style>